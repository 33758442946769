<template>
    <div class="board-container">
        <div class="board-search">
            <div class="board-search__box">
                <input v-model="filter.searchValue" class="board-search__input" type="text" name="" value="">
                <button class="board-search__button" type="submit" name="button" @click="getInquires"></button>
            </div>
            <div class="board-search__select">
                <select v-model="filter.type" class="board-search__selectbox" name="">
                    <option value="" disabled selected>문의항목을 선택해주세요.</option>
                    <option v-for="type in inquireTypes" :value="type">{{ type }}</option>
                </select>
            </div>
        </div>
        <div role="table" class="board-table board-table--inquiry">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader" class="num">번호</div>
                    <div role="columnheader" class="tit">문의사항</div>
                    <div role="columnheader" class="cell">문의유형</div>
                    <div role="columnheader" class="cell">답변상태</div>
                    <div role="columnheader" class="cell">작성일</div>
                </div>
            </div>
            <div v-for="(inquire, index) in inquires">
                <div role="rowgroup">
                    <div role="row">
                        <div role="cell" class="num">{{ (page - 1) * limit + index + 1 }}</div>
                        <div role="cell" class="tit"><div class="tit"><a role="button" :data-idx="index">{{ inquire.subject }}</a></div></div>
                        <div role="cell" class="cell type">{{ inquire.type }}</div>
                        <div role="cell" class="cell state">{{ inquire.reply ? "답변완료": "미답변" }}</div>
                        <div role="cell" class="cell date"><span class="date">{{ inquire.createdAt.toDate() }}</span></div>
                    </div>
                </div>
                <div role="rowgroup" class="answer" :data-idx-answer="index">
                    <div role="row">
                        <div role="cell" class="num"></div>
                        <div role="cell" class="question">
                            <div class="question" v-html="inquire.content.replace(/\n/g,'<br>')"></div>
                        </div>
                    </div>
                    <div v-if="inquire.reply" role="row" class="background">
                        <div role="cell" class="num"><span class="symbol"></span></div>
                        <div role="cell" class="answer">
                            <div class="answer" v-html="inquire.reply.replace(/\n/g, '<br>')"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!inquires.length" class="empty-message">
                문의내역이 없습니다
            </div>
        </div>

        <pagination-component v-model="page" :count="pageCount" @input="getInquires"></pagination-component>
        <!-- <div class="board-buttons">
            <a class="button button--border" href=""><span>리스트</span></a>
            <a class="button button--primary" href=""><span>글쓰기</span></a>
        </div> -->
    </div>
</template>

<script>
import PaginationComponent from "../../../pagination-component.vue";
export default{
	components: {
        PaginationComponent
	},
    data: () => {
        return {
            filter: {
                type: null,
                searchKey: "subject",
                searchValue: null
            },
            page: 1,
			pageCount: 0,
			limit: 10,
			inquires: [],
            inquireTypes: [
                "회원정보 문의",
                "주문/결제 문의",
                "제품 문의",
                "배송 문의",
                "교환/반품 문의",
                "이벤트/혜택 문의",
                "서비스 개선 의견",
                "기타문의"
            ]
        };
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            try{
                await this.getInquires();

                this.$nextTick(() => {
                    // 문의내역 - 답변 토글
                    (function(){
                        let question = document.querySelectorAll('.board-table--inquiry [role="button"]'),
                        answer = document.querySelectorAll('.board-table--inquiry [role="rowgroup"].answer');

                        for(let i = 0; i < question.length; i++)
                        question[i].addEventListener('click', toggleInquiry);

                        function toggleInquiry(){
                            let idx = this.getAttribute('data-idx'),
                            toggle = document.querySelector('.board-table--inquiry [role="rowgroup"][data-idx-answer="'+idx+'"]');

                            if(toggle !== null){
                                if(toggle.getAttribute('data-answer') === 'opened'){
                                    for(let i = 0; i < answer.length; i++)
                                    answer[i].removeAttribute('data-answer');
                                } else {
                                    for(let i = 0; i < answer.length; i++)
                                    answer[i].removeAttribute('data-answer');
                                    toggle.setAttribute('data-answer', 'opened');
                                }
                            }
                        }
                    })();
                });
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
		},
        getInquires(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/me/inquires', {
						headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        },
                        params: this.filter
					});

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
					this.inquires = res.data.inquires;

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
}
</script>
